<template>
  <div id="search">
    <NavTop
      ref="navTop"
      @onSearchByVal="getKeyWord"
      pageName="search"
      :value="searchParams.keyword"
    ></NavTop>
    <div class="banner">
      <img :src="banner.url" alt="" />
    </div>
    <div class="first-level">
      <div class="first-level-list">
        <div
          class="list-button"
          v-if="firstLevelList.length > 9"
          @click="moveFirstLevelList('left')"
        >
          <img src="../../assets/images/icons/search_left.png" alt="" />
        </div>
        <div class="list-tabs">
          <div
            class="tab"
            :style="{
              transform: 'translateX(' + firstMoveWidth + 'px)',
              background:
                searchParams.oneCategoryId == item.id ? '#03bd61' : 'none',
              border:
                searchParams.oneCategoryId == item.id
                  ? 'none'
                  : '1px solid #979797',
            }"
            v-for="item in firstLevelList"
            :key="item.id"
            @click="selectFirstOption(item)"
          >
            {{ item.categoryName }}
          </div>
        </div>
        <div
          class="list-button"
          v-if="firstLevelList.length > 9"
          @click="moveFirstLevelList('right')"
        >
          <img src="../../assets/images/icons/search_right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="second-level" v-show="secondLevelList.length > 0">
      <div class="second-level-list">
        <div
          class="list-button"
          @click="moveShowList('left')"
          v-if="secondLevelList.length > 14"
        >
          <img src="../../assets/images/icons/search_left.png" alt="" />
        </div>
        <div class="list-tabs" v-show="searchParams.oneCategoryId != null">
          <div
            class="tab"
            :style="{
              transform: 'translateX(' + secondMoveWidth + 'px)',
              color:
                searchParams.twoCategoryId == item.id ? '#03bd61' : '#f9f9f9',
            }"
            v-for="item in secondLevelList"
            :key="item.id"
            @click="selectSecondOption(item)"
          >
            {{ item.categoryName }}
          </div>
        </div>
        <div
          class="list-button"
          @click="moveShowList('right')"
          v-show="searchParams.oneCategoryId != null"
          v-if="secondLevelList.length > 14"
        >
          <img src="../../assets/images/icons/search_right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="three-level">
      <div class="three-level-left"></div>
      <div class="filter-bottom-right">
        <span
          :style="{
            color: searchParams.prop == null ? '#03bd61' : '#f3f2f3',
          }"
          @click="selectSort(null)"
          >全部</span
        >
        <span
          :style="{
            color: searchParams.prop == 'upload_time' ? '#03bd61' : '#f3f2f3',
          }"
          @click="selectSort('upload_time')"
          >最新</span
        >
        <span
          :style="{
            color: searchParams.prop == 'hot_value' ? '#03bd61' : '#f3f2f3',
          }"
          @click="selectSort('hot_value')"
          >热门</span
        >
      </div>
    </div>
    <div class="content">
      <div
        class="content-box"
        v-for="item in materialList"
        :key="item.materialInfoVO.materialId"
      >
        <div
          class="content-box-show"
          @mouseenter="item.materialInfoVO.show = true"
          @mouseleave="item.materialInfoVO.show = false"
          @click="
            jumpDetailsPage(
              item.materialInfoVO.materialName,
              item.materialInfoVO.materialId
            )
          "
        >
          <div class="show-image" v-show="!item.materialInfoVO.show">
            <img v-lazy="item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'" alt="" />
          </div>
          <div class="show-image" v-show="item.materialInfoVO.show">
            <img :src="getImageView(item.materialInfoVO.previewUrl) + '?x-oss-process=image/resize,p_20,limit_0,w_480'" alt="" />
            <div class="buttons">
              <div
                class="buttons-collection"
                v-show="
                  !item.materialInfoVO.isCollect ||
                  item.materialInfoVO.isCollect == 0
                "
                @click.stop="openAddDialog(item.materialInfoVO)"
              >
                收藏
              </div>
              <div
                class="buttons-iscollection"
                v-show="item.materialInfoVO.isCollect == 1"
                @click.stop="onCancelCollection(item.materialInfoVO)"
              >
                已收藏
              </div>
              <div
                class="buttons-like"
                @click.stop="
                  onLike(item.materialInfoVO.isLike, item.materialInfoVO)
                "
              >
                <img
                  :src="
                    !item.materialInfoVO.isLike ||
                    item.materialInfoVO.isLike == 0
                      ? require('@/assets/images/icons/icon5.png')
                      : require('@/assets/images/icons/icon6.png')
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="content-box-info">
          <div class="info-title">{{ item.materialInfoVO.materialName }}</div>
<!--          <div class="info-avatar">-->
<!--            <img :src="item.authorVo.avatar" alt="" />-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="searchParams.size"
        :total="materialTotal"
        :current-page="searchParams.current"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 添加收藏弹出框 -->
    <Favorites ref="favorites"></Favorites>
  </div>
</template>

<script>
import NavTop from "../../components/NavTop/NavTop.vue";
import Favorites from "@/components/Favorites/Favorites.vue";
import homeApi from "@/api/home.js";
import userApi from "@/api/user.js";
export default {
  components: {
    NavTop,
    Favorites
  },
  data() {
    return {
      banner: "",
      firstLevelList: [], //一级分类列表
      secondLevelList: [], //二级分类列表
      firstMoveWidth: 0, //一级分类列表移动宽度
      secondMoveWidth: 0, //二级分类列表移动宽度
      searchParams: {
        userId: this.$store.state.userId ? this.$store.state.userId : null,
        oneCategoryId: null,
        twoCategoryId: null,
        prop: null,
        keyword: null,
        size: 12,
        current: 1,
      },
      materialList: [], //搜索列表
      materialTotal: 0, //搜索列表总条数
    };
  },
  created() {
    this.searchParams.keyword = this.$route.query.keyword || null;
    this.searchParams.prop = this.$route.query.prop || null;
    this.getBannerData(); //获取banner图
    this.getCategoryData(); //获取筛选数据
    this.onSearch();
  },
  mounted() {},
  methods: {
    // 获取首页轮播
    getBannerData() {
      homeApi
        .postBannerData({
          location: 3,
        })
        .then((res) => {
          this.banner = res.data.data[0];
        });
    },
    // 跳转素材详情页
    jumpDetailsPage(name, material) {
      let routeData = this.$router.resolve({
        path: "/home/details",
        query: {
          name,
          material,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 获取筛选数据
    getCategoryData() {
      homeApi.getCategoryData().then((res) => {
        if (res.data.code == 200) {
          this.firstLevelList = res.data.data;
          this.firstLevelList.forEach((item) => {
            item.children.unshift({
              categoryName: "全部",
            });
          });
          this.firstLevelList.unshift({
            categoryName: "全部",
          });
        }
      });
    },
    // 选择一级分类
    selectFirstOption(item) {
      if (item.id) {
        this.searchParams.oneCategoryId = item.id;
        this.secondLevelList = item.children;
      } else {
        this.searchParams.oneCategoryId = null;
        this.secondLevelList = [];
      }
      this.secondMoveWidth = 0;
      this.searchParams.twoCategoryId = null;
      this.searchParams.current = 1;
      this.onSearch();
    },
    // 选择二级分类
    selectSecondOption(item) {
      if (item.id) {
        this.searchParams.twoCategoryId = item.id;
      } else {
        this.searchParams.twoCategoryId = null;
      }
      this.searchParams.current = 1;
      this.onSearch();
    },
    // 选择排序
    selectSort(prop) {
      this.searchParams.prop = prop;
      this.searchParams.current = 1;
      this.onSearch();
    },
    // 左右拉动一级筛选
    moveFirstLevelList(direction) {
      if (this.firstLevelList.length > 9) {
        if (direction == "left" && this.firstMoveWidth < 0) {
          this.firstMoveWidth = this.firstMoveWidth + 162;
        } else if (
          direction == "right" &&
          this.firstMoveWidth >= -(this.firstLevelList.length - 10) * 162
        ) {
          this.firstMoveWidth = this.firstMoveWidth - 162;
        }
      }
    },
    // 左右拉动二级筛选
    moveShowList(direction) {
      if (this.secondLevelList.length > 14) {
        if (direction == "left" && this.secondMoveWidth < 0) {
          this.secondMoveWidth = this.secondMoveWidth + 104;
        } else if (
          direction == "right" &&
          this.secondMoveWidth >= -(this.secondLevelList.length - 15) * 104
        ) {
          this.secondMoveWidth = this.secondMoveWidth - 104;
        }
      }
    },
    // 切换列表页数
    handleCurrentChange(val) {
      this.searchParams.current = val;
      this.onSearch();
    },
    // 获取子组件传值
    getKeyWord(val) {
      this.searchParams.keyword = val;
      this.onSearch();
    },
    // 搜索
    onSearch() {
      homeApi.postSearchData(this.searchParams).then((res) => {
        if (res.data.code == 200) {
          this.materialList = res.data.data.list;
          this.materialTotal = res.data.data.total;
        }
      });
    },
    // 打开添加收藏弹框
    openAddDialog(item) {
      if (this.$store.state.userInfo) {
        this.$refs.favorites.staging = item;
        this.$refs.favorites.visible = true;
        this.$refs.favorites.collectionParams.materialId = item.materialId;
        this.$refs.favorites.getFavoritesData();
      } else {
        this.$refs.navTop.openLoginDialog();
      }
    },
    // 取消收藏
    onCancelCollection(item) {
      this.$refs.favorites.onCancelCollection(item);
    },
    // 点赞/取消点赞
    onLike(num, item) {
      if (this.$store.state.userInfo) {
        if (num == 0) {
          userApi
            .postAddLike({
              materialId: item.materialId,
              userId: this.$store.state.userId,
            })
            .then((res) => {
              if (res.data.code == 200) {
                item.isLike = 1;
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          userApi
            .postCancelLike({
              materialId: item.materialId,
              userId: this.$store.state.userId,
            })
            .then((res) => {
              if (res.data.code == 200) {
                item.isLike = 0;
              } else {
                this.$message.error(res.data.msg);
              }
            });
        }
      } else {
        this.$refs.navTop.openLoginDialog();
      }
    },
  },
};
</script>

<style lang="less" scoped>
#search {
  width: 100%;
  min-height: 100vh;
  //background-color: #010301;
  padding-bottom: 60px;
  box-sizing: border-box;

  .banner {
    max-width: 2560px;
    min-width: 1600px;
    height: 500px;
    margin: 0 auto;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .first-level {
    width: 100%;
    padding: 25px 0;
    box-sizing: border-box;

    .first-level-list {
      width: 1600px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .list-tabs {
        width: 1458px;
        white-space: nowrap;
        overflow: hidden;

        .tab {
          width: 124px;
          height: 45px;
          margin: 0 19px;
          border-radius: 5px;
          box-sizing: border-box;
          overflow: hidden;
          text-align: center;
          line-height: 45px;
          font-size: 18px;
          font-weight: 400;
          color: #f9f9f9;
          display: inline-block;
          cursor: pointer;
        }
      }

      .list-button {
        width: 30px;
        height: 35px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .second-level {
    width: 100%;
    margin-bottom: 25px;
    background: linear-gradient(
      134deg,
      rgba(238, 238, 238, 0.1) 0%,
      rgba(216, 216, 216, 0.1) 100%
    );
    border-image: linear-gradient(
        311deg,
        rgba(157, 152, 253, 0.2),
        rgba(255, 255, 255, 0.2)
      )
      2 2;
    border-top: 2px solid;
    border-bottom: 2px solid;
    padding: 5px 0;
    box-sizing: border-box;

    .second-level-list {
      width: 1600px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .list-button {
        width: 30px;
        height: 35px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .list-tabs {
        width: 1456px;
        white-space: nowrap;
        overflow: hidden;

        .tab {
          width: 104px;
          height: 45px;
          border-radius: 5px;
          box-sizing: border-box;
          overflow: hidden;
          text-align: center;
          line-height: 45px;
          font-size: 16px;
          font-weight: 400;
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }

  .three-level {
    width: 1600px;
    margin: 0 auto;
    padding: 0 0 25px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-bottom-right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        margin-left: 40px;
        font-size: 16px;
        font-weight: 300;
        cursor: pointer;
      }
    }
  }

  .content {
    width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .content-box {
      width: 376px;
      height: 211.5px;
      margin-right: 32px;
      margin-bottom: 32px;
      border-radius: 10px;
      cursor: pointer;
      position: relative;

      .content-box-show {
        width: 100%;
        height: 100%;

        .show-image {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            //width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .buttons {
            width: 100%;
            padding: 10px 16px 0;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;

            .buttons-collection {
              width: 65px;
              height: 38px;
              background: #fff429;
              border-radius: 5px;
              text-align: center;
              line-height: 38px;
              font-size: 16px;
              color: #000000;
            }

            .buttons-iscollection {
              width: 65px;
              height: 38px;
              background: #343736;
              border-radius: 5px;
              text-align: center;
              line-height: 38px;
              font-size: 16px;
              color: #ffffff;
            }

            .buttons-like {
              width: 38px;
              height: 38px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .content-box-info {
        width: 100%;
        padding: 0 16px 10px;
        box-sizing: border-box;
        border-radius: 0 0 10px 10px;
        background: linear-gradient(
          180deg,
          rgba(29, 33, 41, 0) 0,
          rgba(29, 33, 41, 0.8) 100%
        );
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;

        .info-title {
          width: calc(100% - 80px);
          font-size: 18px;
          font-weight: 400;
          color: #f9f9f9;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .info-avatar {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }

          img[src=""],
          img:not([src]) {
            opacity: 0;
          }
        }
      }
    }
  }

  .content .content-box:nth-of-type(4n) {
    margin-right: 0;
  }

  .content .content-box:nth-of-type(n + 9) {
    margin-bottom: 0;
  }

  .pagination {
    width: 100%;
    margin-top: 30px;
    text-align: center;

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: rgb(3, 189, 97);
      color: #fff;
    }

    /deep/ .el-pager li:hover {
      color: rgb(3, 189, 97);
    }
  }

  .add-dialog /deep/ .el-dialog {
    padding: 30px 0 66px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
    background-color: #171918;
    border: 1px solid #555555;
    box-sizing: border-box;

    .el-dialog__header {
      padding: 0;

      .el-dialog__title {
        font-size: 26px;
        font-weight: 400;
        color: #f9f9f9;
      }

      .el-dialog__headerbtn {
        font-size: 24px;
      }
    }

    .el-dialog__body {
      width: 100%;
      padding: 0;
      box-sizing: border-box;

      .add-dialog-content {
        width: 100%;
        margin-top: 30px;

        .content-border {
          width: calc(100% - 76px);
          height: 2px;
          margin: 0 auto;
          padding: 0 30px;
          box-sizing: border-box;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.4),
            rgba(157, 152, 253, 0.5)
          );
        }

        .content-center {
          width: 100%;
          height: 322px;
          padding: 18px 38px 0;
          box-sizing: border-box;
          overflow: hidden;

          .content-center-slip {
            width: 100%;
            margin: 16px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-size: 16px;
              font-weight: 400;
              color: #f9f9f9;
            }

            button {
              width: 82px;
              height: 38px;
              background: #fff429;
              border-radius: 10px;
              cursor: pointer;
            }
          }
        }

        .content-bottom {
          width: 100%;
          padding: 13px 25px;
          box-sizing: border-box;
          background: linear-gradient(
            134deg,
            rgba(238, 238, 238, 0.2) 0%,
            rgba(216, 216, 216, 0.2) 100%
          );
          border-radius: 0px 0px 10px 10px;
          position: absolute;
          left: 0;
          bottom: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .content-bottom-ipt {
            width: calc(100% - 130px);
            height: 40px;
            padding: 0 20px;
            box-sizing: border-box;
            background: #d8d8d8;
            border-radius: 10px;
            border: 1px solid #979797;

            input {
              width: 100%;
              height: 100%;
              border: none;
              background: none;
              outline: none;
              line-height: 40px;
              font-size: 16px;
              font-weight: 400;
              color: #626363;
            }
          }

          .content-bottom-create {
            width: 110px;
            height: 40px;
            background: #fff429;
            border: 1px solid #fff429;
            box-sizing: border-box;
            border-radius: 10px;
            margin-left: 10px;
            font-size: 18px;
            font-weight: 600;
            color: #111714;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
