var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"search"}},[_c('NavTop',{ref:"navTop",attrs:{"pageName":"search","value":_vm.searchParams.keyword},on:{"onSearchByVal":_vm.getKeyWord}}),_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":_vm.banner.url,"alt":""}})]),_c('div',{staticClass:"first-level"},[_c('div',{staticClass:"first-level-list"},[(_vm.firstLevelList.length > 9)?_c('div',{staticClass:"list-button",on:{"click":function($event){return _vm.moveFirstLevelList('left')}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/search_left.png"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"list-tabs"},_vm._l((_vm.firstLevelList),function(item){return _c('div',{key:item.id,staticClass:"tab",style:({
              transform: 'translateX(' + _vm.firstMoveWidth + 'px)',
              background:
                _vm.searchParams.oneCategoryId == item.id ? '#03bd61' : 'none',
              border:
                _vm.searchParams.oneCategoryId == item.id
                  ? 'none'
                  : '1px solid #979797',
            }),on:{"click":function($event){return _vm.selectFirstOption(item)}}},[_vm._v(" "+_vm._s(item.categoryName)+" ")])}),0),(_vm.firstLevelList.length > 9)?_c('div',{staticClass:"list-button",on:{"click":function($event){return _vm.moveFirstLevelList('right')}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/search_right.png"),"alt":""}})]):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.secondLevelList.length > 0),expression:"secondLevelList.length > 0"}],staticClass:"second-level"},[_c('div',{staticClass:"second-level-list"},[(_vm.secondLevelList.length > 14)?_c('div',{staticClass:"list-button",on:{"click":function($event){return _vm.moveShowList('left')}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/search_left.png"),"alt":""}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchParams.oneCategoryId != null),expression:"searchParams.oneCategoryId != null"}],staticClass:"list-tabs"},_vm._l((_vm.secondLevelList),function(item){return _c('div',{key:item.id,staticClass:"tab",style:({
              transform: 'translateX(' + _vm.secondMoveWidth + 'px)',
              color:
                _vm.searchParams.twoCategoryId == item.id ? '#03bd61' : '#f9f9f9',
            }),on:{"click":function($event){return _vm.selectSecondOption(item)}}},[_vm._v(" "+_vm._s(item.categoryName)+" ")])}),0),(_vm.secondLevelList.length > 14)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchParams.oneCategoryId != null),expression:"searchParams.oneCategoryId != null"}],staticClass:"list-button",on:{"click":function($event){return _vm.moveShowList('right')}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/search_right.png"),"alt":""}})]):_vm._e()])]),_c('div',{staticClass:"three-level"},[_c('div',{staticClass:"three-level-left"}),_c('div',{staticClass:"filter-bottom-right"},[_c('span',{style:({
            color: _vm.searchParams.prop == null ? '#03bd61' : '#f3f2f3',
          }),on:{"click":function($event){return _vm.selectSort(null)}}},[_vm._v("全部")]),_c('span',{style:({
            color: _vm.searchParams.prop == 'upload_time' ? '#03bd61' : '#f3f2f3',
          }),on:{"click":function($event){return _vm.selectSort('upload_time')}}},[_vm._v("最新")]),_c('span',{style:({
            color: _vm.searchParams.prop == 'hot_value' ? '#03bd61' : '#f3f2f3',
          }),on:{"click":function($event){return _vm.selectSort('hot_value')}}},[_vm._v("热门")])])]),_c('div',{staticClass:"content"},_vm._l((_vm.materialList),function(item){return _c('div',{key:item.materialInfoVO.materialId,staticClass:"content-box"},[_c('div',{staticClass:"content-box-show",on:{"mouseenter":function($event){item.materialInfoVO.show = true},"mouseleave":function($event){item.materialInfoVO.show = false},"click":function($event){return _vm.jumpDetailsPage(
              item.materialInfoVO.materialName,
              item.materialInfoVO.materialId
            )}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.materialInfoVO.show),expression:"!item.materialInfoVO.show"}],staticClass:"show-image"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'),expression:"item.materialInfoVO.cover + '?x-oss-process=image/resize,p_20,limit_0,w_480'"}],attrs:{"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.materialInfoVO.show),expression:"item.materialInfoVO.show"}],staticClass:"show-image"},[_c('img',{attrs:{"src":_vm.getImageView(item.materialInfoVO.previewUrl) + '?x-oss-process=image/resize,p_20,limit_0,w_480',"alt":""}}),_c('div',{staticClass:"buttons"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  !item.materialInfoVO.isCollect ||
                  item.materialInfoVO.isCollect == 0
                ),expression:"\n                  !item.materialInfoVO.isCollect ||\n                  item.materialInfoVO.isCollect == 0\n                "}],staticClass:"buttons-collection",on:{"click":function($event){$event.stopPropagation();return _vm.openAddDialog(item.materialInfoVO)}}},[_vm._v(" 收藏 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.materialInfoVO.isCollect == 1),expression:"item.materialInfoVO.isCollect == 1"}],staticClass:"buttons-iscollection",on:{"click":function($event){$event.stopPropagation();return _vm.onCancelCollection(item.materialInfoVO)}}},[_vm._v(" 已收藏 ")]),_c('div',{staticClass:"buttons-like",on:{"click":function($event){$event.stopPropagation();return _vm.onLike(item.materialInfoVO.isLike, item.materialInfoVO)}}},[_c('img',{attrs:{"src":!item.materialInfoVO.isLike ||
                    item.materialInfoVO.isLike == 0
                      ? require('@/assets/images/icons/icon5.png')
                      : require('@/assets/images/icons/icon6.png'),"alt":""}})])])])]),_c('div',{staticClass:"content-box-info"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(item.materialInfoVO.materialName))])])])}),0),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.searchParams.size,"total":_vm.materialTotal,"current-page":_vm.searchParams.current},on:{"current-change":_vm.handleCurrentChange}})],1),_c('Favorites',{ref:"favorites"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }