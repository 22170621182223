var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.pageName != '' && _vm.scrollHeight < _vm.screenHeight ? 'light' : 'deep',attrs:{"id":"navtop"}},[_c('div',{staticClass:"nav-content"},[_c('router-link',{attrs:{"to":{
          path: '/',
          query: {
            name: 'SHOWINGS首映式',
          },
        }}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo/logo.png"),"alt":""}})])]),_c('div',{staticClass:"search"},[_vm._m(0),_c('div',{staticClass:"search-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],attrs:{"type":"text","placeholder":"搜索"},domProps:{"value":(_vm.keyword)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSearch()},"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}})])]),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"icon",on:{"click":function($event){return _vm.jumpPersonalPage(3)}}},[_c('img',{attrs:{"src":require("@/assets/images/home/icon4.png"),"alt":""}})]),_c('div',{staticClass:"icon2",on:{"click":function($event){return _vm.jumpPersonalPage(2)}}},[_c('img',{attrs:{"src":require("@/assets/images/home/icon1.png"),"alt":""}})]),(_vm.$store.state.userInfo)?_c('div',{staticClass:"avatar",on:{"click":function($event){return _vm.jumpPersonalPage(5)}}},[_c('img',{attrs:{"src":_vm.$store.state.userInfo.avatar,"alt":""}}),_c('div',{staticClass:"show"},[_c('p',{on:{"click":function($event){$event.stopPropagation();return _vm.jumpPersonalPage(5)}}},[_vm._v("个人中心")]),_c('p',{on:{"click":function($event){$event.stopPropagation();return _vm.outLogin()}}},[_vm._v("退出登录")])])]):_vm._e(),(!_vm.$store.state.userInfo)?_c('div',{staticClass:"login",on:{"click":function($event){return _vm.openLoginDialog()}}},[_vm._v(" 登录/注册 ")]):_vm._e()])],1)]),_c('Login',{ref:"login"}),_c('Register',{ref:"register"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-icon"},[_c('img',{attrs:{"src":require("@/assets/images/home/icon2.png"),"alt":""}})])
}]

export { render, staticRenderFns }