<template>
  <div>
    <div
      id="navtop"
      :class="pageName != '' && scrollHeight < screenHeight ? 'light' : 'deep'"
    >
      <div class="nav-content">
        <router-link
          :to="{
            path: '/',
            query: {
              name: 'SHOWINGS首映式',
            },
          }"
        >
          <div class="logo">
            <img src="@/assets/images/logo/logo.png" alt="" />
          </div>
        </router-link>
        <div class="search">
          <div class="search-icon">
            <img src="@/assets/images/home/icon2.png" alt="" />
          </div>
          <div class="search-input">
            <input
              type="text"
              v-model="keyword"
              @keyup.enter="onSearch()"
              placeholder="搜索"
            />
          </div>
        </div>
        <div class="tabs">
          <div class="icon" @click="jumpPersonalPage(3)">
            <img src="@/assets/images/home/icon4.png" alt="" />
          </div>
          <div class="icon2" @click="jumpPersonalPage(2)">
            <img src="@/assets/images/home/icon1.png" alt="" />
          </div>
          <div
            class="avatar"
            v-if="$store.state.userInfo"
            @click="jumpPersonalPage(5)"
          >
            <img :src="$store.state.userInfo.avatar" alt="" />
            <div class="show">
              <p @click.stop="jumpPersonalPage(5)">个人中心</p>
              <p @click.stop="outLogin()">退出登录</p>
            </div>
          </div>
          <div
            class="login"
            v-if="!$store.state.userInfo"
            @click="openLoginDialog()"
          >
            登录/注册
          </div>
        </div>
      </div>
    </div>

    <!--登录弹框-->
    <Login ref="login"></Login>

    <!--注册弹框-->
    <Register ref="register"></Register>
  </div>
</template>

<script>
import { Message } from "element-ui";
import Login from "@/components/Login/Login.vue";
import Register from "@/components/Register/Register.vue";
import loginApi from "@/api/login.js";
export default {
  components: {
    Login,
    Register
  },
  props: {
    pageName: {
      type: String,
      requied: "",
    },
    value: {
      type: String,
      requied: "",
    },
  },
  inject: ["reload"],
  data() {
    return {
      scrollHeight: 0, //页面滚动高度
      screenHeight: 0, //屏幕高度
      keyword: "", //搜索关键字
    };
  },
  created() {
    this.keyword = this.value;
  },
  mounted() {
    if (this.pageName == "index") {
      this.screenHeight = document.documentElement.clientHeight;
      window.addEventListener("scroll", this.handleScroll);
    } else if (this.pageName == "personal") {
      this.screenHeight = 400;
      window.addEventListener("scroll", this.handleScroll);
    } else if (this.pageName == "search") {
      this.screenHeight = 400;
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    // 获取页面滚动高度
    handleScroll() {
      this.scrollHeight = document.documentElement.scrollTop;
    },
    // 回车搜索事件
    onSearch() {
      if (this.pageName != "search") {
        this.$router.push({
          path: "/home/search",
          query: {
            name: 'SHOWINGS首映式',
            keyword: this.keyword,
          },
        });
      } else {
        this.$emit("onSearchByVal", this.keyword);
      }
    },
    // 跳转个人页
    jumpPersonalPage(index) {
      if (this.$store.state.userInfo) {
        window.localStorage.setItem('tabIndex', index);
        let routeData = this.$router.resolve({
          path: "/user/personal",
          query: {
            name: "个人主页"
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.openLoginDialog();
      }
    },
    // 打开登录弹框
    openLoginDialog() {
      this.$refs.login.phoneNumber = "";
      this.$refs.login.verificationCode = "";
      this.$refs.login.password = "";
      clearInterval(this.$refs.login.timer);
      this.$refs.login.time = 60;
      this.$refs.login.timer = null;
      this.$refs.login.isRegister = false;
      this.$refs.login.isPassword = false;
      this.$refs.register.visible = false;
      this.$refs.login.visible = true;
    },
    // 打开注册弹框
    openRegisterDialog(boolean) {
      this.$refs.register.phoneNumber = "";
      this.$refs.register.verificationCode = "";
      this.$refs.register.password = "";
      clearInterval(this.$refs.register.timer);
      this.$refs.register.time = 60;
      this.$refs.register.timer = null;
      this.$refs.login.visible = false;
      this.$refs.register.isRegister = boolean;
      this.$refs.register.visible = true;
    },
    // 退出登录
    outLogin() {
      loginApi.postLogout(this.$store.state.token).then((res) => {
        if (res.data.code == 200) {
          Message.success(res.data.msg);
          this.$store.state.userInfo = "";
          this.$store.state.token = "";
          this.$store.state.userId = null;
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          this.$router.push({
            path: "/",
            query: {
              name: 'SHOWINGS首映式',
            },
          });
          // this.reload(); //刷新页面
        } else {
          Message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.light {
  background-color: rgba(1, 3, 1, 0.6);
}

.deep {
  // background: linear-gradient(
  //   134deg,
  //   rgba(238, 238, 238, 0.1) 0%,
  //   rgba(216, 216, 216, 0.1) 100%
  // );
  background-color: #010301;
}

#navtop {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;

  .nav-content {
    max-width: 1600px;
    min-width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .logo {
      width: 233px;
      height: 51px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .search {
      width: 401px;
      height: 59px;
      background: url("../../assets/images/home/searchbg.png") no-repeat;
      background-size: 100% 100%;
      padding: 0 22px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .search-icon {
        width: 23px;
        height: 23px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .search-input {
        width: calc(100% - 36px);
        margin-left: 13px;

        input {
          background: none;
          border: none;
          outline: none;
          font-size: 20px;
          font-weight: 400;
          color: #f9f9f9;
        }

        input::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #f9f9f9;
        }
        input::-moz-placeholder {
          /* Firefox 19+ */
          color: #f9f9f9;
        }
        input:-ms-input-placeholder {
          /* IE 10+ */
          color: #f9f9f9;
        }
        input:-moz-placeholder {
          /* Firefox 18- */
          color: #f9f9f9;
        }
      }
    }

    .tabs {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .icon {
        width: 31px;
        height: 31px;
        margin-left: 27px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .icon2 {
        width: 26px;
        height: 31px;
        margin-left: 27px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .avatar {
        width: 35px;
        height: 35px;
        margin-left: 27px;
        cursor: pointer;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        .show {
          width: 84px;
          padding: 5px 0;
          background: linear-gradient(
            134deg,
            rgba(238, 238, 238, 0.1) 0%,
            rgba(216, 216, 216, 0.1) 100%
          );
          background-color: #010301;
          border-radius: 6px;
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translateX(-50%);
          display: none;
          z-index: 9999;

          p {
            padding: 5px 0;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #f9f9f9;
          }

          p:hover {
            color: #03bd61;
          }
        }
      }

      .avatar:hover {
        .show {
          display: block;
        }
      }

      .login {
        margin-left: 27px;
        cursor: pointer;
        font-size: 16px;
        color: #ffffff;
      }

      .login:hover {
        color: #03bd61;
      }
    }
  }

  // @media screen and (max-width: 1600px) {
  //   .nav-content {
  //     width: 1280px;
  //   }
  // }
}

.login-dialog /deep/ .el-dialog {
  margin-top: 21vh !important;
  background: #171918;
  border-radius: 10px;
  border: 1px solid #555555;

  .el-dialog__headerbtn {
    font-size: 24px;
  }

  .el-dialog__body {
    width: 100%;
    padding: 0;

    .dialog-title {
      margin-bottom: 33px;
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      color: #f9f9f9;
    }

    .dialog-phone {
      width: 400px;
      height: 44px;
      margin: 0 auto 36px;
      background: linear-gradient(
        134deg,
        rgba(238, 238, 238, 0.1) 0%,
        rgba(216, 216, 216, 0.1) 100%
      );
      border: 2px solid;
      box-sizing: border-box;
      border-image: linear-gradient(
          311deg,
          rgba(157, 152, 253, 0.2),
          rgba(255, 255, 255, 0.2)
        )
        2 2;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dialog-phone-area {
        width: 25%;
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        color: #f9f9f9;
        border-right: 2px solid;
        border-color: rgba(70, 76, 85, 0.8);
      }

      .dialog-phone-number {
        width: 75%;
        padding: 0 32px;
        box-sizing: border-box;

        input {
          width: 100%;
          font-size: 22px;
          font-weight: 400;
          color: #ffffff;
          border: none;
          background-color: transparent;
          outline: none;
        }
      }
    }

    .dialog-verification {
      width: 400px;
      height: 44px;
      margin: 0 auto 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dialog-verification-code {
        width: 55%;
        height: 44px;
        background: linear-gradient(
          134deg,
          rgba(238, 238, 238, 0.2) 0%,
          rgba(216, 216, 216, 0.1) 100%
        );
        border: 2px solid;
        box-sizing: border-box;
        border-image: linear-gradient(
            311deg,
            rgba(157, 152, 253, 0.2),
            rgba(255, 255, 255, 0.2)
          )
          2 2;
        backdrop-filter: blur(30px);

        input {
          width: 100%;
          font-size: 22px;
          font-weight: 400;
          color: #ffffff;
          border: none;
          background-color: transparent;
          outline: none;
          line-height: 39px;
          text-align: center;
        }
      }

      .dialog-verification-btn {
        width: 40%;
        height: 44px;
        padding: 0 20px;
        background: linear-gradient(
          134deg,
          rgba(238, 238, 238, 0.2) 0%,
          rgba(216, 216, 216, 0.1) 100%
        );
        border: 2px solid;
        box-sizing: border-box;
        border-image: linear-gradient(
            311deg,
            rgba(157, 152, 253, 0.2),
            rgba(255, 255, 255, 0.2)
          )
          2 2;
        backdrop-filter: blur(30px);
        line-height: 39px;
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        color: #f9f9f9;
        cursor: pointer;
      }
    }

    .dialog-btn {
      width: 260px;
      height: 46px;
      background-color: #03bd61;
      margin: 15px auto 30px;
      border-radius: 4px;
      font-size: 24px;
      text-align: center;
      font-weight: 400;
      color: #f9f9f9;
      line-height: 46px;
      cursor: pointer;
    }

    .dialog-agreement {
      width: 100%;
      padding: 0 44px 30px;
      box-sizing: border-box;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #f9f9f9;

      a {
        color: #03bd61;
      }
    }
  }
}
</style>
