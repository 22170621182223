<template>
  <el-dialog
      custom-class="add-dialog"
      title="添加至收藏夹"
      :visible.sync="visible"
      width="625px"
      top="21vh"
      center
  >
    <div class="add-dialog-content">
      <div class="content-border"></div>
      <div
          class="content-center"
          :style="{ 'overflow-y': favoritesList.length > 5 ? 'scroll' : '' }"
      >
        <div
            class="content-center-slip"
            v-for="item in favoritesList"
            :key="item.id"
        >
          <span>{{ item.name }}</span>
          <button @click="onSelectFavorites(item.id)">收藏</button>
        </div>
      </div>
      <div class="content-bottom">
        <div class="content-bottom-ipt">
          <input
              type="text"
              v-model="favoritesName"
              maxlength="8"
              placeholder="收藏夹名称"
              @keyup.enter="createFavorites"
          />
        </div>
        <button class="content-bottom-create" @click="createFavorites">
          新建
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import userApi from "@/api/user";

export default {
  data() {
    return {
      visible: false, //添加收藏弹框
      favoritesList: [], //收藏夹列表
      // 添加收藏参数
      collectionParams: {
        favoriteId: "",
        materialId: "",
      },
      staging: "", //暂存字段
      favoritesName: "", //新建收藏夹名称
    }
  },
  created() {

  },
  methods: {
    // 获取收藏夹列表
    getFavoritesData() {
      userApi
          .postFavoritesData({
            userId: this.$store.state.userId,
          })
          .then((res) => {
            this.favoritesList = res.data.data;
          });
    },
    // 选择收藏至文件夹
    onSelectFavorites(id) {
      this.collectionParams.favoriteId = id;
      this.collectionParams.userId = this.$store.state.userId;
      userApi.postUserCollection(this.collectionParams).then((res) => {
        this.visible = false;
        if (res.data.code == 200) {
          this.staging.isCollect = 1;
          this.$message.success("收藏成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 创建收藏夹
    createFavorites() {
      if (this.favoritesName) {
        userApi
            .postCreateFavorites({
              name: this.favoritesName,
              userId: this.$store.state.userId,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.favoritesName = "";
                this.$message.success("创建成功");
                this.getFavoritesData(); //刷新收藏夹
              } else {
                this.$message.error(res.data.msg);
              }
            });
      } else {
        this.$message.error("请输入收藏夹名称");
      }
    },
    // 取消收藏
    onCancelCollection(item) {
      this.staging = item;
      userApi
          .postFavoritesId({
            materialId: item.materialId,
            userId: this.$store.state.userId,
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.cancelCollection(res.data.data);
            }
          });
    },
    cancelCollection(params) {
      userApi
          .postCancelCollection({
            favoriteId: params.favoriteId,
            materialId: params.materialId,
            userId: this.$store.state.userId,
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.staging.isCollect = 0;
            } else {
              this.$message.error(res.data.msg);
            }
          });
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .add-dialog {
  padding: 30px 0 66px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 21px;
  background-color: #171918;
  border: 1px solid #555555;
  box-sizing: border-box;

  .el-dialog__header {
    padding: 0;

    .el-dialog__title {
      font-size: 26px;
      font-weight: 400;
      color: #f9f9f9;
    }

    .el-dialog__headerbtn {
      font-size: 24px;
    }
  }

  .el-dialog__body {
    width: 100%;
    padding: 0;
    box-sizing: border-box;

    .add-dialog-content {
      width: 100%;
      margin-top: 30px;

      .content-border {
        width: calc(100% - 76px);
        height: 2px;
        margin: 0 auto;
        padding: 0 30px;
        box-sizing: border-box;
        background: linear-gradient(to right,
        rgba(255, 255, 255, 0.4),
        rgba(157, 152, 253, 0.5));
      }

      .content-center {
        width: 100%;
        height: 322px;
        padding: 18px 38px 0;
        box-sizing: border-box;
        overflow: hidden;

        .content-center-slip {
          width: 100%;
          margin: 16px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 16px;
            font-weight: 400;
            color: #f9f9f9;
          }

          button {
            width: 82px;
            height: 38px;
            background: #fff429;
            border-radius: 19px;
            cursor: pointer;
          }
        }
      }

      .content-bottom {
        width: 100%;
        padding: 13px 25px;
        box-sizing: border-box;
        background: linear-gradient(134deg,
        rgba(238, 238, 238, 0.2) 0%,
        rgba(216, 216, 216, 0.2) 100%);
        border-radius: 0px 0px 21px 21px;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content-bottom-ipt {
          width: calc(100% - 130px);
          height: 40px;
          padding: 0 20px;
          box-sizing: border-box;
          background: #d8d8d8;
          border-radius: 12px;
          border: 1px solid #979797;

          input {
            width: 100%;
            height: 100%;
            border: none;
            background: none;
            outline: none;
            line-height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: #626363;
          }
        }

        .content-bottom-create {
          width: 110px;
          height: 40px;
          background: #fff429;
          border: 1px solid #fff429;
          box-sizing: border-box;
          border-radius: 12px;
          margin-left: 10px;
          font-size: 18px;
          font-weight: 600;
          color: #111714;
          cursor: pointer;
        }
      }
    }
  }
}
</style>